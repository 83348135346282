import React from 'react';
import styles from './Projects.module.css';
import FTWToken from './../../imgs/projects/FTWToken.png';

const Experience3 = () => {
  return (
    <>
      <div className={styles.project}>
        <figure className={styles.project__img}>
          <a href='https://feedtheworldtoken.com' target='_blank' rel='noreferrer'>
            <img src={FTWToken} alt='FeedTheWorldToken' />
          </a>
        </figure>
        <figcaption className={styles.project__caption}>
          <div className={styles.project__name}>FeedtheWorldToken</div>
          <p className={styles.project__details}>
            A cryptocurreny company that I co-founded with a friend. The smart contract was written in Solidity and the website was written in React.
          </p>
          <div className={styles.project__details__links}>
            <ul>
              <li className={styles.project__links}>
                <a href='https://feedtheworldtoken.com' target='_blank' rel='noreferrer'>
                  Visit Website
                </a>
              </li>
            </ul>
          </div>
        </figcaption>
      </div>
      <div className={styles.project__stack}>
        <ul>
          <li>REACT</li>
          <li>SOLIDITY</li>
        </ul>
      </div>
    </>
  );
};

export default Experience3;
