import React from 'react';
import styles from './Skills.module.css';

const Tools = () => {
  return (
    <div className={styles.skills__category}>
      <div className={styles.skills__category__label}>Tools</div>
      <ul>
        <li className={styles.skills__category__item}>Firebase</li>
        <li className={styles.skills__category__item}>Git & Github</li>
        <li className={styles.skills__category__item}>Oh My Zsh</li>
        <li className={styles.skills__category__item}>Chrome DevTools</li>
        <li className={styles.skills__category__item}>Postman</li>
        <li className={styles.skills__category__item}>Material UI</li>
        <li className={styles.skills__category__item}>Tailwind UI</li>
        <li className={styles.skills__category__item}>Bootstrap</li>
      </ul>
    </div>
  );
};

export default Tools;
