import React from 'react';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footer__copyright}>
        <div className='top'>
          Design by{' '}
          <a
            href='https://github.com/bchiang7/bchiang7.github.io'
            target='_blank'
            rel='noreferrer'
          >
            Brittany Chiang
          </a>
          .
        </div>
        <div className='bottom'>Coded by Dominick Lamb.</div>
      </div>
      <div className={styles.footer__links}>
        <a href='mailto:domlamb7@gmail.com' target='_blank' rel='noreferrer'>
          DOMLAMB7@GMAIL.COM
        </a>
        <a href='https://github.com/dlamb22' target='_blank' rel='noreferrer'>
          GitHub
        </a>
        <a href='https://www.linkedin.com/in/dominicklamb/'>LinkedIn</a>
      </div>
    </footer>
  );
};

export default Footer;
