import React from 'react';
import Frameworks from './Frameworks';
import Languages from './Languages';
import Tools from './Tools';
import styles from './Skills.module.css';

const Skills = () => {
  return (
    <div className={styles.section}>
      <div className={styles.section__title}>Skills</div>
      <div className={styles.section__content}>
        <div className={styles.skills}>
          <Languages />
          <Frameworks />
          <Tools />
        </div>
      </div>
    </div>
  );
};

export default Skills;
