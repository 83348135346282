import React from 'react';
import styles from './Projects.module.css';
import PRVAudio from './../../imgs/projects/PRV.png';

const Experience1 = () => {
  return (
    <>
      <div className={styles.project}>
        <figure className={styles.project__img}>
          <a href="https://prvaudio.com" target="_blank" rel="noreferrer">
            <img src={PRVAudio} alt="upcscout" />
          </a>
        </figure>
        <figcaption className={styles.project__caption}>
          <div className={styles.project__name}>PRV Audio</div>
          <p className={styles.project__details}>
            My current full time job for an Ecommerce based company that sells
            professional audio equipment. The tech stack is PHP, Javascript &
            jQuery.
          </p>
          <div className={styles.project__details__links}>
            <ul>
              <li className={styles.project__links}>
                <a href="https://prvaudio.com" target="_blank" rel="noreferrer">
                  Visit Website
                </a>
              </li>
            </ul>
          </div>
        </figcaption>
      </div>
      <div className={styles.project__stack}>
        <ul>
          <li>PHP</li>
          <li>MYSQL</li>
          <li>JS</li>
          <li>jQuery</li>
        </ul>
      </div>
    </>
  );
};

export default Experience1;
