import React from 'react';
import styles from './Projects.module.css';
import BOLOscout from './../../imgs/projects/BOLOscout.png';

const Experience4 = () => {
  return (
    <>
      <div className={styles.project}>
        <figure className={styles.project__img}>
          <a href="https://boloscout.com" target="_blank" rel="noreferrer">
            <img src={BOLOscout} alt="BOLOscout" />
          </a>
        </figure>
        <figcaption className={styles.project__caption}>
          <div className={styles.project__name}>BOLOscout</div>
          <p className={styles.project__details}>
            An Apple and Android app that I created for Amazon sellers to share
            items that they find at the store. Written in React Native.
          </p>
          <div className={styles.project__details__links}>
            <ul>
              <li className={styles.project__links}>
                <a
                  href="https://boloscout.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Visit Website
                </a>
              </li>
            </ul>
          </div>
        </figcaption>
      </div>
      <div className={styles.project__stack}>
        <ul>
          <li>REACT NATIVE</li>
          <li>FIREBASE</li>
          <li>MUI</li>
        </ul>
      </div>
    </>
  );
};

export default Experience4;
