import React from 'react';
import styles from './Projects.module.css';
import Go2Lister from './../../imgs/projects/Go2Lister.png';

const Experience2 = () => {
  return (
    <>
      <div className={styles.project}>
        <figure className={styles.project__img}>
          <a href="https://go2lister.web.app" target="_blank" rel="noreferrer">
            <img src={Go2Lister} alt="Go2Lister" />
          </a>
        </figure>
        <figcaption className={styles.project__caption}>
          <div className={styles.project__name}>Go2Lister</div>
          <p className={styles.project__details}>
            Freelance work for a software as a service company for Amazon sellers. I created using React, Node.js,
            Amazon SP-API, Firebase Hosting, Firebase Functions & MUI.
          </p>
          <div className={styles.project__details__links}>
            <ul>
              <li className={styles.project__links}>
                <a
                  href="https://go2lister.web.app"
                  target="_blank"
                  rel="noreferrer"
                >
                  Visit Website
                </a>
              </li>
            </ul>
          </div>
        </figcaption>
      </div>
      <div className={styles.project__stack}>
        <ul>
          <li>REACT</li>
          <li>NODE.JS</li>
          <li>AMAZON SP-API</li>
          <li>FIREBASE</li>
          <li>MUI</li>
        </ul>
      </div>
    </>
  );
};

export default Experience2;
