import React from 'react';
import styles from './Skills.module.css';

const Frameworks = () => {
  return (
    <div className={styles.skills__category}>
      <div className={styles.skills__category__label}>Frameworks</div>
      <ul>
        <li className={styles.skills__category__item}>React</li>
        <li className={styles.skills__category__item}>React Native</li>
        <li className={styles.skills__category__item}>Node.js</li>
        <li className={styles.skills__category__item}>Express</li>
        <li className={styles.skills__category__item}>jQuery</li>
      </ul>
    </div>
  );
};

export default Frameworks;
