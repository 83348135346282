import React from 'react';
import styles from './Social.module.css';

const Social = () => {
  return (
    <div className={styles.social__icons}>
      <a href='mailto:domlamb7@gmail.com' target='_blank' rel='noreferrer'>
        <span className={styles.email} />
      </a>
      <a href='https://github.com/dlamb22' target='_blank' rel='noreferrer'>
        <span className={styles.github} />
      </a>
      <a
        href='https://www.linkedin.com/in/dominicklamb/'
        target='_blank'
        rel='noreferrer'
      >
        <span className={styles.linkedin} />
      </a>
    </div>
  );
};

export default Social;
