import React from 'react'
import styles from './Skills.module.css';

const Languages = () => {
  return (
    <div className={styles.skills__category}>
      <div className={styles.skills__category__label}>Languages</div>
      <ul>
        <li className={styles.skills__category__item}>Javascript (ES6)</li>
        <li className={styles.skills__category__item}>PHP</li>
        <li className={styles.skills__category__item}>HTML</li>
        <li className={styles.skills__category__item}>CSS</li>
      </ul>
    </div>
  );
}

export default Languages
