import React, { useState, useEffect } from 'react';
import styles from './ScrollToTop.module.css';
import scrollBtn from './../../imgs/emojis/pointing-up.png';

const ScrollToTop = ({ showBelow }) => {
  const [show, setShow] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: `smooth` });
  };

  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll);
      return () => window.removeEventListener(`scroll`, handleScroll);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {show && (
        <div className={styles.top_button} onClick={handleClick}>
          <img src={scrollBtn} alt='' />
        </div>
      )}
    </>
  );
};

export default ScrollToTop;
