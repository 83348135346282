import React from 'react';
import Experience1 from './Experience1';
import Experience2 from './Experience2';
import Experience3 from './Experience3';
import Experience4 from './Experience4';
import Experience5 from './Experience5';
import styles from './Projects.module.css';

const Projects = () => {
  return (
    <div className={styles.section}>
      <div className={styles.section__title}>Experience</div>
      <div className={styles.section__content}>
        <Experience1 />
        <Experience2 />
        <Experience3 />
        <Experience4 />
        <Experience5 />
      </div>
    </div>
  );
};

export default Projects;
