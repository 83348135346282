import React from 'react';
import Footer from './components/footer/Footer';
import ScrollToTop from './components/scroll/ScrollToTop';
import Intro from './components/intro/Intro';
import Experience from './components/experience/Experience';
import Skills from './components/skills/Skills';
import Social from './components/social/Social';

const App = () => {
  return (
    <>
      <Social />
      <Intro />
      <Skills />
      <Experience />
      <Footer />
      <ScrollToTop showBelow={500} />
    </>
  );
};

export default App;
