import React from 'react';
import styles from './Intro.module.css';
import resume from './../../assets/DominickLamb.pdf';

const Intro = () => {
  return (
    <header className={styles.intro}>
      <h1 className={styles.intro__hello}>
        Hello! <span className={styles.wave}></span>
      </h1>
      <h2 className={styles.intro__about}>
        I'm <span className={styles.intro__about__name}>Dominick Lamb,</span> a
        front end software developer from Florida, USA. Coding is my passion.
      </h2>
      <div className={styles.intro__resume}>
        <div className={styles.animate__point}>
          <span className={styles.pointright} />
          <h3 className={styles.intro__resume__text}>
            <a href={resume} target='_blank' rel='noreferrer'>
              View My Resume
            </a>
          </h3>
        </div>
      </div>
    </header>
  );
};

export default Intro;
